import React from 'react';
import './index.scss';
import { bindActionCreators } from 'redux'
import logoPng from '../../asset/images/logo.png'
import { connect } from 'react-redux'
import {Container, Row, Col, Button} from 'react-bootstrap';
import Finance from '../../layout/finance';
import { useNavigate } from 'react-router-dom'

const DashboardLayout = (props) => {
    
  const navigate = useNavigate();  

    return (
    <>    
    <Container fluid className={'ew-dashboard-header'}>
        <center><img src={logoPng} width={'5%'} alt={'finance system'} /></center>
        <center><p className={'text-white pt-4'}>Financial Portfolio Management System</p></center>
        <Row className="justify-content-center">
                <Col md={3} className="text-center">
            <Button variant={'danger'} onClick={() => navigate('/portfolio/create')}> Create Portfolio </Button>
            </Col>
        </Row>
    </Container>
    <Container className={'ew-dashboard'}>
        <Row>
            <Col md={12} sm={12} lg={12}>
            <Finance />
            </Col>
        </Row>
    </Container>
    </>
    );
}


const mapStateToProps = (state) => {
    return {
      logout: state.logout,
      login: state.login
    }
  };
    
  const mapDispatchToProps = (dispatch) => {
      return bindActionCreators({
      }, dispatch);
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);