import React, {useEffect} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {getTokenRequest} from '../db/action/token';
import Auth from '../layout/auth';
import Footer from '../layout/footer';
import Content from '../layout/content';
import {Spinner, Row, Col, Container} from 'react-bootstrap';

function App(props) {
  
  const navigate = useNavigate();
  useEffect(() => {
      props.getTokenRequest();
  }, []);

  return props.token.accessToken ? ( 
    <div className={`main-wrapper`}>
      <Auth>
        <Content/>
      </Auth>
      <Footer/>
    </div>
  ) : <Container fluid><Row className={' align-items-center justify-content-center'}>
        <Col md={1}> 
        <Spinner animation={'border'} variant={'success'}/>
        </Col>
      </Row></Container>;
}

const mapStateToProps = (state) => {
  return {
    logout: state.logout,
    token: state.token,
    login: state.login
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getTokenRequest
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);