import React, {useState, useEffect} from 'react';
import {InputGroup, Form, Button, Card, Row, Col, ListGroup, ListGroupItem} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {  HiArrowCircleLeft, HiArrowCircleDown, HiArrowNarrowUp, HiChevronDoubleRight } from 'react-icons/hi';
import { isUndefined, isNumber } from 'lodash'
import PageHeader from '../PageHeader';
import Error from '../Error';
import isAlpha from 'validator/lib/isAlpha'


const Portfolio = (props) => {
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    const [portfolio, setUser] = useState({
        assetType: '',
        quantity:'',
        purchasePrice:'',
        date: "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        const object = {...portfolio};
        if(name === 'quantity' || name === 'purchasePrice') {
            object[name] = Number(value);
        } else {
            object[name] = value;
        }
        setUser(object);
    };

    const handlePortfolio = () => { 
        if (isAlpha(portfolio.assetType) === false) {
              setInfo({
                  ...info,
                    content: 'Invalid AssetType',
                    variant: 'warning'
              });
              setShowError(true);
              return;  
        }
        if (isNumber(portfolio.purchasePrice) === false) {
            setInfo({
                ...info,
                  content: 'Invalid Purchase Price',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isNumber(portfolio.quantity) === false) {
            setInfo({
                ...info,
                  content: 'Invalid Quantity',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (portfolio.date === '') {
            setInfo({
                ...info,
                  content: 'Invalid Date',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        setIsPreview(true);
    }

    useEffect(() => {
        const {success, code, message} = portfolio;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            navigate('/dashboard');
        } else {
            if(code === 'PAY032') {
                navigate('/active');
            } 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [portfolio]);

    if(isPreview === true) {
        return (
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Portfolio Information</Card.Title>
                <ListGroup variant="flush">
                  <ListGroupItem><strong>Asset Type:</strong> {portfolio.assetType}</ListGroupItem>
                  <ListGroupItem><strong>Purchase Price:</strong> ${Number(portfolio.purchasePrice) + ' INR'}</ListGroupItem>
                  <ListGroupItem><strong>Quantity:</strong> {Number(portfolio.quantity)}</ListGroupItem>
                  <ListGroupItem><strong>Date of Purchase:</strong> {new Date(portfolio.date).toLocaleDateString()}</ListGroupItem>
                </ListGroup>
              </Card.Body>
              <Card.Footer>
              <Form.Group className={'mt-2'}>
                <Row>
                    <Col md={12}>
                    <Button
                        onClick={() => navigate('/')}
                    >Submit</Button>
                    </Col>
                </Row>
            </Form.Group>
              </Card.Footer>
            </Card>
          );
    }

    return (
        <React.Fragment>
        <PageHeader title={'Portfolio'} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card border={'light'} className={'border-0'}>
            <Form.Group className={'mb-2 border ew-border-gradient'}>
                <InputGroup>
                <InputGroup.Text className={'bg-white border-0'}><HiArrowCircleLeft /></InputGroup.Text>
                <Form.Control type={'text'} size={'lg'} placeholder={'Asset Type'} className={'border-0'} required
                    defaultValue={portfolio.assetType} name={'assetType'} onChange={(event) => handleChange(event)} /> 
                </InputGroup>
            </Form.Group>
            <Form.Group className={'mb-2 border ew-border-gradient'}>
                <InputGroup>
                <InputGroup.Text className={'bg-white border-0'}><HiArrowCircleDown /></InputGroup.Text>
                <Form.Control size={'lg'} 
                className={'border-0'} 
                placeholder={'Purchase Price'} required type={'number'}
                defaultValue={portfolio.purchasePrice} name={'purchasePrice'} onChange={(event) => handleChange(event)} /> 
                <InputGroup.Text className={'bg-white border-0'}>
                    INR
                </InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group className={'mb-2 border ew-border-gradient'}>
                <InputGroup>
                <InputGroup.Text className={'bg-white border-0'}><HiArrowNarrowUp /></InputGroup.Text>
                <Form.Control type={'number'} size={'lg'} placeholder={'Quantity'} className={'border-0'} required
                    defaultValue={portfolio.quantity} name={'quantity'} onChange={(event) => handleChange(event)} /> 
                </InputGroup>
            </Form.Group>
            <Form.Group className={'mb-2 border ew-border-gradient'}>
                <InputGroup>
                <InputGroup.Text className={'bg-white border-0'}><HiChevronDoubleRight /></InputGroup.Text>
                <Form.Control type={'date'} size={'lg'} placeholder={'Date'} className={'border-0'} required
                    defaultValue={portfolio.date} name={'date'} onChange={(event) => handleChange(event)} /> 
                </InputGroup>
            </Form.Group>
            <Form.Group className={'mt-2'}>
                <Row>
                    <Col md={6}>
                    <Button
                        onClick={() => handlePortfolio(true)}
                    >Portfolio</Button>
                    </Col>
                    <Col md={6}>
                    <Button
                        onClick={() => navigate('/')}
                    >cancel</Button>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group className={'m-2 text-center'}>
                <hr/>
            </Form.Group>      
        </Card>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {}
    }
  };
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);