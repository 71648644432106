import {Route, Routes, Navigate} from 'react-router-dom';
import Portfolio from '../../layout/portfolio';
import Dashboard from '../../layout/dashboard';

const Routers = ({login}) => {

    return ( 
    <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='portfolio/create' element={<Portfolio />} />
        <Route path='*' element={<Navigate to={'/'} />} />
    </Routes>
    );
}
export default Routers;