import https from '../../axios';

const assets = [
    { asset: 'Stocks', percentage: 50, color: 'success' },
    { asset: 'Bonds', percentage: 30, color: 'info' },
    { asset: 'Real Estate', percentage: 15, color: 'warning' },
    { asset: 'Cash', percentage: 5, color: 'primary' },
];


export const doGetAsset = async (params) => {
    try {
        return { data: {'success' : true, 'message': 'sucess', assets } }; 
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doSearchPlans = async (params) => {
    try {
        return await https.post('/plans/search', params);
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doListPlans = async (params) => {
    try {
        return await https.post('/plans/list', params);
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doBranchPlans = async (params) => {
    const { branch_id } = params;
    try {
        return await https.post('/plans/branch', {branch_id});
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doRemovePlans = async (params) => {
    const { plans_id, is_deleted } = params;
    try {
        return await https.post('/plans/remove', { plans_id, is_deleted });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doUpdatePlans = async (params) => {
    const {
        name, branch_id, categories_id, code, summary, payment_upi, url, video_url,
        phoneCode, phone, email, country,line1,line2, city, created_by,
        location, plans_id, start_date, end_date, type, state
    } = params;
    try {
        return await https.post('/plans/update', {
            name, branch_id, categories_id, code, summary, payment_upi, url, video_url,
        phoneCode, phone, email, country,line1,line2, city, created_by,
        location, plans_id, start_date, end_date, type, state
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}
