import React, { Suspense} from 'react';
import { useParams } from 'react-router-dom';
import './index.scss';
import {Container, Row, Col} from 'react-bootstrap';

const AssetAllocationCard = React.lazy(() => import('../../components/AssetAllocation'));
const MarketTrends = React.lazy(() => import('../../components/MarketTrends'));
const PerformanceMetrics = React.lazy(() => import('../../components/PerformanceMetrics'));
const PortfolioPerformanceChart = React.lazy(() => import('../../components/PerformanceChart'));

const FinanceLayout = (props) => {
    const params = useParams();
    return (
    <>    
    <Container fluid className={'ew-plans'}>
        <Row className={'justify-content-center vh-100 align-item-center pt-3'}>
            <Col md={4}>
                <Suspense fallback={<div>Loading...</div>}>
                    <AssetAllocationCard/>
                </Suspense>
            </Col>
            <Col md={4}>
            <Suspense fallback={<div>Loading...</div>}>
                <MarketTrends />
             </Suspense>   
            </Col>
            <Col md={4}>
            <Suspense fallback={<div>Loading...</div>}>
            <PerformanceMetrics />
            </Suspense>
            </Col>
            <Col md={12}>
            <Suspense fallback={<div>Loading...</div>}>
            <PortfolioPerformanceChart/>
            </Suspense>
            </Col>
        </Row>
    </Container>
    </>
    );
}

export default FinanceLayout;