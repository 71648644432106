import { combineReducers } from 'redux';
import token from '../db/reducer/token';
import portfolio from '../db/reducer/portfolio';

const appReducer = combineReducers({
  portfolio,
  token,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;

