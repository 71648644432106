import React, {useState} from 'react';
import './index.scss';
import {Container, Row, Col} from 'react-bootstrap';
import Portfolio from '../../components/Portfolio';
const PortfolioLayout = (props) => {
    return (
    <>    
    <Container fluid className={'ew-login-layout'}>
    <Row className="justify-content-center">
            <Col md={4} className="text-center mt-5">    
                        <Portfolio />
                    </Col>   
        </Row>
    </Container>
    </>
    );
}

export default PortfolioLayout;