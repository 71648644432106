import {  
    GET_ASSETS_REQUEST,
    GET_ASSETS_SUCCESS,
  } from '../../actionTypes/portfolio';
  
  const portfolioReducer = (state = {}, action) => {
    switch (action.type) {  
      case GET_ASSETS_REQUEST:
          state = { ...state, getAsset: [] };
          break;  
    
      case GET_ASSETS_SUCCESS:
        state = { ...state,
                  getAsset:{
                      ...action.payload  
                  }
              };
        break;
      default:
        break;
    }
    return state;
  };
  export default portfolioReducer;