import axios from 'axios';
const https = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 50000
});

https.interceptors.request.use(function (config) {
    const { store } = require('../store');
    const db = store.getState();
    const token = db.token.accessToken;
    config.headers.Authorization =  'Bearer ' + token;
    return config;
});

export default https;