import {call, put, takeLatest} from 'redux-saga/effects';
import {
    GET_ASSETS_REQUEST, 
    GET_ASSETS_SUCCESS
} from '../../actionTypes/portfolio';

import { doGetAsset } from '../../../com/portfolio';

function* getAssetFetch(params) {
    const json = yield call(doGetAsset, params.payload);   
    yield put({ type: GET_ASSETS_SUCCESS, payload: json.data});
}

export function* getAssetSaga() {
    yield takeLatest(GET_ASSETS_REQUEST, getAssetFetch)
}
